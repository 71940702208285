import styled from 'styled-components'
import * as palette from '../styles/variables'
import { device, width } from '../styles/device'


export const AboutUsStyled = styled.div`
    position: relative;
    background-color: #990fc3;
    /* background-image: linear-gradient(135deg,#ea309d 0,#ea30d3 16%,#541bbf 52%,#541fbb 32%,#0dd3c5 100%,#86c1e6 100%); */
    background-image: linear-gradient(120deg,hsl(283, 94%, 29%),#1068ec);

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 5rem 15px;
        max-width: ${width.xl};
        margin: 0 auto;

        @media ${device.tablet} {
            flex-direction: row;
        }

        .imageWrap {
            width: 100%;
            border: 15px solid ${palette.magenta};
            border-radius: 50%;
            background: #eb05c9;
            max-width: 300px;
            margin: 0 auto 3rem auto;
            position: relative;

            @media ${device.laptop} {
                border: 20px solid ${palette.magenta};
            }

            @media ${device.tablet} {
                margin-bottom: 0;
            }

            @media ${device.mobile} {
                margin: 0 2rem 3rem 0;
            }
        }

        .textWrap {
            color: ${palette.white};
            margin-left: 1rem;
            line-height: ${palette.lineHeightMd};
            z-index: 1;

            .title {
                font-weight: ${palette.fontWeightTitle};
                font-family: 'bebas-neue', sans-serif;
                font-size: ${palette.fontSizeXl};
                display: inline-block;
            }

            .text1{
                margin-bottom: 5rem;
            }

            .text2 {
                p {
                    margin-bottom: 0;
                }
            }

            .underLine {
                padding-top: 1rem;
                width: 25%;
                border-bottom: 5px solid ${palette.white};
            }
        }
    }

    .dots {
        position: absolute;
        top: 0;
        left: 0;
    }
`;

