import styled from 'styled-components'
import * as palette from '../../styles/variables'
import { device, width } from '../../styles/device'

const TitleMainStyled = styled.div`
    background-color: ${palette.superDarkGrey};
    padding: 4rem 0;
    border-bottom: 1px solid ${palette.grey};

    @media ${device.tablet} {
        padding: 4rem 0;
    }

    .container {
        max-width: ${width.xl};
        margin: 0 auto;
        padding-left: 15px;
        padding-right: 15px;
        display: ${props => props.twoColumns ? 'flex' : 'block'};
        flex-direction: ${props => props.twoColumns ? 'column' : 'unset'};
        align-items: flex-start;

        @media ${device.tablet} {
            flex-direction: ${props => props.twoColumns ? 'row' : 'unset'};
        }

        .twoColumns {
            width: 100%;
            margin-bottom: 5rem;

            @media ${device.tablet} {
                width: 30%;
                margin-bottom: 0;
            }

            &.casino {
                margin-top: 0;
            }
        }

        .featureWrap {
            display: flex;
            flex-wrap: wrap;
            margin-left: 0;

            @media ${device.tablet} {
                margin-left: 5rem;
            }

            >div {
                flex-grow: 1;
                width: 100%;

                @media ${device.mobile} {
                    width: 33%;
                }
            }

            >div:nth-child(1) {
                padding-bottom: 0;

                @media ${device.mobile} {
                    padding-bottom: 5rem;
                }
            }

            &.casino {

                >div:nth-child(1) {
                    padding-bottom: 0;
                }
            }
        }
    }

    .title {
        color: ${palette.white};
    }

    .text {
        color: ${palette.white};
        margin-bottom: 0;
    }
`;

export default TitleMainStyled
