import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {AboutUsStyled} from './aboutUsStyled'
import dots from '../images/dots.svg'
import Markdown from 'react-markdown'



const AboutUs = () => {
    const data = useStaticQuery(graphql`
    query AboutUsQuery {
        allContentfulAboutUs {
            edges {
              node {
                image {
                  file {
                    url
                  }
                  sizes(resizingBehavior: SCALE) {
                    ...GatsbyContentfulSizes_withWebp
                   }
                }
                text {
                  text
                }
                text2 {
                  text2
                }
                title
                title2
                id
              }
            }
          }
        }
    `)
    if(!data.allContentfulAboutUs.edges) {
        return <div>Loading...</div>
    } else {
        return data.allContentfulAboutUs.edges.map((edge) => {
            if(edge.node && edge.node.image && edge.node.image.file) {
                return (
                    <AboutUsStyled key={edge.node.id}>
                        <div className="container">
                            <div className="imageWrap">
                                <img src={edge.node.image && edge.node.image.file.url} alt={edge.node.title} />
                            </div>
                            <div className="textWrap">
                                <div className="text1">
                                    <h1 className="title">{edge.node.title}
                                        <div className="underLine" />
                                    </h1>
                                    <Markdown source={edge.node.text.text} />
                                </div>
                                <div className="text2">
                                    <h2 className="title">{edge.node.title2}
                                        <div className="underLine" />
                                    </h2>
                                    <Markdown source={edge.node.text2.text2} />
                                </div>
                            </div>
                        </div>
                        <img src={dots} alt="dots" className="dots"/>
                    </AboutUsStyled>
                )
            }
            return true;
        })
    }

}
 export default AboutUs

