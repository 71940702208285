import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import OverViewBlock from './overViewBlock'


const ShortOverView = () => {
    const data = useStaticQuery(graphql`
    query ShortOverViewQuery {
        allContentfulShortOverview(sort: {fields: orderNumber}) {
            edges {
              node {
                id
                image {
                  file {
                    url
                  }
                  sizes(maxHeight: 1000, quality: 90, resizingBehavior: SCALE) {
                    ...GatsbyContentfulSizes_withWebp
                   }
                }
                video {
                  file {
                    url
                  }
                }
                title
                linkTo
                subTitle
                listOverview {
                  listOverview
                }
                backgroundImage {
                  sizes(maxHeight: 1000, quality: 90, resizingBehavior: SCALE) {
                    ...GatsbyContentfulSizes_withWebp
                  }
                  file {
                    fileName
                    url
                  }
                }
              }
            }
        }
    }
    `)

    if(!data.allContentfulShortOverview.edges) {
        return <div>Loading...</div>
    } else {
        return data.allContentfulShortOverview.edges.map((edge, idx) => {
          if(edge.node && edge.node.image && edge.node.image.file && edge.node.listOverview) {
            const imageLayout = idx % 2 === 0 ? 'left' : 'right'
            const video = edge.node.video ? edge.node.video.file.url : null
            const backgroundImage = edge.node.backgroundImage ? edge.node.backgroundImage : null
            if(edge.node && edge.node.title !== "Casino") {
              return <OverViewBlock node={edge.node}
                                layout={imageLayout}
                                html={edge.node.listOverview.listOverview}
                                key={edge.node.id}
                                className='HomeList'
                                imageUrl={edge.node.image.url}
                                video={video}
                                backgroundImage={backgroundImage}
                    />
            }

          }
          return true
        })

    }

}
 export default ShortOverView

