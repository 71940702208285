import React from "react"
import Clients from './clients'
import {MeetClientsStyled} from './meetClientsStyled'



class MeetClients extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            count: 0
        }
    }
    intervalID = 0;
    componentDidMount() {
        this.intervalID = setInterval(this.slideNext.bind(this), 2000);
    }
    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    slideNext() {
        const sliderWrapper = document.getElementById('slider-wrapper')
        this.setState({ count: this.state.count + 1 })
        if(this.state.count >= sliderWrapper.children.length) {
            this.setState({ count: 0 })
        }
        this.changeSlidePosition(0, '', 'beforeEnd')
        const sliderItemWidth = `${sliderWrapper.children[0].offsetWidth}`
        const item = sliderWrapper.children[0]
        item.style.marginLeft = `-${sliderItemWidth}px`
    }
    changeSlidePosition(i, marginLeft, position) {
        const sliderWrapper = document.getElementById('slider-wrapper')
        const slideToChange = sliderWrapper.children[i]

        slideToChange.style.marginLeft = marginLeft;
        sliderWrapper.removeChild(slideToChange);
        sliderWrapper.insertAdjacentHTML(position, slideToChange.outerHTML);
    }


    render() {
        return (
            <MeetClientsStyled>
                <div className="container">
                  <h2 className="title">Trusted by
                  </h2>
                  <div className="clientsWrap" id="slider-wrapper">
                      <Clients />
                  </div>
                </div>
            </MeetClientsStyled>
        )
    }
}

 export default MeetClients

