import styled from 'styled-components'
import * as palette from '../styles/variables'
import { device, width } from '../styles/device'


export const MeetClientsStyled = styled.div`
     .container {
        max-width: ${width.xl};
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-top: 3rem;
        padding-right: 15px;
        padding-bottom: 2rem;
        padding-left: 15px;

        @media ${device.tablet} {
            flex-direction: row;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .title {
        text-align: center;
        margin-bottom: 20px;
        font-weight: 600;
        color: ${palette.darkGrey};
        font-family: 'bebas-neue', sans-serif;
        font-size: ${palette.fontSizeLg};
        font-weight: ${palette.fontWeightTitle};
        width: 100%;
        display: inline-block;

        @media ${device.tablet} {
            width: 20%;
            text-align: left;
            margin-bottom: 0;
        }

        .underLine {
            padding-top: 1rem;
            width: 25%;
            border-bottom: 5px solid ${palette.darkGrey};
            margin: 0 auto;
        }
    }

    .clientsWrap {
        display: flex;
        overflow: hidden;
        width: 80%;
    }

    .imageWrap {
        width: 100%;
        padding: 1rem;
        align-self: center;
        min-width: 50%;
        transition: all 0.4s ease-in-out;

        @media ${device.mobile} {
            min-width: 25%;
        }
        img {
            max-width: 120px;
            margin: 0 auto;
            filter: opacity(60%);
            -webkit-filter: grayscale(100%);
            cursor: pointer;
        }

        img:hover {
            filter:opacity(100%);
        }
    }

`;

