import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/hero'
import { FaAngleDoubleRight } from 'react-icons/fa'
import ShortOverView from '../components/shortOverView'
import AboutUs from '../components/aboutUs'
import MeetClients from '../components/meetClients'
import License from '../components/license'
import Title from '../components/title'
import TitleMainStyled from './styles/titleMainStyled'



const IndexPage = () => {
  const data = useStaticQuery(graphql`
  query IndexPageQuery {
    allContentfulMetaDescriptionHome(filter: {position: {eq: "Home"}}) {
      edges {
        node {
          text
        }
      }
    }
  }
`)
  if(!data.allContentfulMetaDescriptionHome.edges) {
    return <div>Loading...</div>
  }
  const descriptionMeta = data.allContentfulMetaDescriptionHome.edges[0].node.text
  return (
    <Layout>
      <SEO title="Home" description={descriptionMeta} />
      <Hero position="Home"
      buttonText="Contact us"
      classButton="heroLanding"
      iconButton={<FaAngleDoubleRight />}
      bannerImage={false}
      noBackgroundHero={false}
      className="homeHero">
      </Hero>
      <TitleMainStyled>
      <div className="container">
        <Title position="Home" />
      </div>
      </TitleMainStyled>
      <ShortOverView />
      <AboutUs />
      <MeetClients />
      <License />
    </Layout>
  )

}
 export default IndexPage

